<template>
  <div class="header" ref="aqthearder">
    <div
        class="return"
        @click="back"
        v-if="!['/index'].includes($route.path)">
      <i class="cubeic-back"></i>
    </div>

    <div class="header_title aqt_ce_ve" @dblclick="backTop">
      <p v-if="['/index_mn'].includes($route.path)" class="titlefont aqt_lang_mn">
        {{ title.mn ? title.mn : title.zh | U2M }}</p>
      <!--      <img v-if="['/index'].includes($route.path)" :src="headerimg" alt style="height: 98%;"/>-->
    </div>
    <div
        class="header_search"
        v-if="['/index_mn'].includes($route.path) && showSearch">
      <book-search @on-search-click="onSearchClick"></book-search>
    </div>
    <!--    <div-->
    <!--      class="header_mn"-->
    <!--      v-if="['/index_mn'].includes($route.path)"-->
    <!--      ref="header_mn"-->
    <!--      @click="goZh">-->
    <!--      <span>中文</span>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {log} from "util";

export default {
  components: {
    BookSearch: (resolve) => {
      require(["@/components/BookSearch.vue"], resolve);
    },
  },
  data() {
    return {
      title: localStorage.getItem("theme_color") ? JSON.parse(localStorage.getItem("theme_color")).name : "",
      library_id: localStorage.getItem("theme_color") ? JSON.parse(localStorage.getItem("theme_color")).library_id : "",
      headerimg: 'http://localhost:35642/img/indexbanner.4836896d.png',
      condition: {
        title: "",
        name: "", //书名
        category_id: "", //书籍分类id
        culture_id: "", //文化分类id
        press_id: "", //出版社id
        library_id: this.library_id, //馆藏id  48内蒙古财经大学图书馆
        isbn: "", //书号
        author: "", //作者名称
        type: null, //1.纸质2电子
        start_time: "", //出版开始时间
        end_time: "", //出版结束时间
        page: 1,
        height: "",
      },
      showSearch: true,
    }
  },
  methods: {
    goZh() {
      this.$router.push('/index_cn')
    },
    backTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    back() {
      this.$router.go(-1);
    },
    /*****************************************************************
     *
     * 点击搜索
     *
     *****************************************************************/
    onSearchClick(item) {
      let _this = this;
      _this.isclick = false;

      if (_this.$route.path == '/AuthorListNew') {
        _this.condition = {name: item.name}
        _this.$store.state.search = _this.condition;
        return
      }
      if (_this.$route.path == '/Resourcevideo') {
        _this.condition = {fid: item.fid}
        _this.$store.state.search = _this.condition;
        return
      }


      if (_this.$route.path == "/newslist") {
        _this.condition.title = item.name; //杂志名
        _this.$store.state.search = _this.condition;
        return;
      }
      _this.condition.name = item.name; //书名
      _this.condition.category_id = item.category_id; //书籍分类id
      _this.condition.culture_id = item.culture_id; //文化分类id
      _this.condition.press_id = item.press_id; //出版社id
      _this.condition.isbn = item.isbn; //书号
      _this.condition.author = item.author; //作者名称
      _this.condition.start_time = item.start_time; //出版开始时间
      _this.condition.end_time = item.end_time; //出版结束时间
      _this.condition.library_id = _this.library_id //馆藏id  48内蒙古财经大学图书馆
      _this.searchStr =
          item.name +
          "  " +
          item.category_name +
          "  " +
          item.culture_name +
          "  " +
          item.press_name +
          "  " +
          item.isbn +
          "  " +
          item.author +
          "  " +
          item.start_time +
          "  " +
          item.end_time;

      if (_this.$route.path == "/ebooklist") {
        _this.condition.type = 2;
      } else if (_this.$route.path == "/Books") {
        _this.condition.type = 1;
      }
      _this.$store.state.search = _this.condition;
    },

    getsidebar_pic() {
      const _this = this;
      const id = this.$route.query.number ? this.$route.query.number : (JSON.parse(localStorage.getItem("theme_color")).library_id ? JSON.parse(localStorage.getItem("theme_color")).library_id : '')
      _this.$http.get('api/web/v1/h5/search?library_id=' + id)
          .then((val) => {
            console.log(val, 88)
            this.title = val.data.name_lang
          }).catch((err) => {
      })
    },
  },
  mounted() {
    this.title = localStorage.getItem("theme_color") ? JSON.parse(localStorage.getItem("theme_color")).name : ""
    this.getsidebar_pic()
  },
  created() {
  },
  computed: {},
  watch: {
    $route(newName, oldName) {
      this.title = localStorage.getItem("theme_color") ? JSON.parse(localStorage.getItem("theme_color")).name : "";
      this.condition = {
        name: "", //书名
        category_id: "", //书籍分类id
        category_name: "", //书籍分类名称
        culture_id: "", //文化分类id
        culture_name: "", //文化分类名称
        press_id: "", //出版社id
        press_name: "", //出版社名称
        isbn: "", //书号
        author: "", //作者名称
        start_time: "", //出版开始时间
        end_time: "", //出版结束时间
        library_id: this.library_id
      };
    },
  },
};
</script>

<style lang="less">
.header {
  width: 100%;
  height: 5rem;
  background: var(--themeColor);
  display: flex;
  color: #ffffff;
  line-height: 5rem;
  font-size: 2rem;
  text-align: center;
  // justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  .bcid {
    width: 500px;
    height: 700px;
    bottom: 0;
    position: static;
  }

  .return {
    width: 8%;
    height: 100%;
    z-index: 5;
  }

  .saoma {
    margin-left: 2rem;
    width: 8%;
    height: 100%;

    i {
      font-size: 1.6rem;
    }
  }

  .iconshuaxin {
    z-index: 999999;
    margin-left: 2rem;
    font-size: 2rem;
  }

  .header_title {

    width: 100%;
    height: 100%;
    position: absolute;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: auto;
    z-index: 2;
    padding: 1rem 5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      //width: 100%;
      //height: 100%;
      font-size: 1.2rem;
      white-space: pre-wrap;
      line-height: 1.8rem;
    }
  }

  .header_mn {
    height: 100%;
    position: absolute;
    right: 1rem;
    z-index: 5;

    span {
      font-size: 16px;
      width: 2rem;
      height: 4rem;
      // margin-right: 1rem;
    }
  }

  .header_search {
    width: 8%;
    height: 100%;
    position: absolute;
    //right: 5rem;
    right: 1rem;
    z-index: 5;

    img {
      width: 2rem;
      height: 2rem;
      // margin-right: 1rem;
    }
  }
}
</style>
